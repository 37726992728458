// TODO: Consider removing it, we shouldn't save large strings anyway to local storage and this bloats the vendors bundle
import pako from 'pako'

import { getUrlParam } from 'utils/UrlUtils'
import { isClient } from 'utils/runtimeUtils'
import { logger } from './logger'

/* global localStorage */

const STRING_LENGTH_COMPRESSION_THRESHOLD = 50000 // Above this size, compress the string before saving to localStorage
const COMPRESSED_STRING_MAGIC = '#compressed#'

class StorageManager {
  static set<T>(key: string, value: T | null): void {
    if (!isClient()) return

    if (value === undefined) return

    // Always initialize as a string; JSON.stringify(null) returns "null"
    let valueString: string = JSON.stringify(value)

    if (value !== null) {
      // Compress the string if needed (don't compress experiments or tiles)
      if (
        valueString.length > STRING_LENGTH_COMPRESSION_THRESHOLD &&
        !['tiles', 'experiments'].includes(key)
      ) {
        try {
          valueString =
            COMPRESSED_STRING_MAGIC +
            pako.deflate(valueString, {
              to: 'string',
            } as unknown as pako.DeflateFunctionOptions)
        } catch (error) {
          logger.error(
            'Error while compressing value, saving without compression',
            error,
            { key }
          )
        }
      }
    }

    try {
      localStorage.setItem(key, valueString)
    } catch (e) {
      logger.warning('Write to local storage failed', {
        error: e,
        key,
      })
    }
  }

  static get<T>(key: string): T | null {
    if (!isClient()) return null

    let value = localStorage.getItem(key)
    if (value === undefined) return null

    // Decompress the string if needed
    if (value && value.startsWith(COMPRESSED_STRING_MAGIC)) {
      value = pako.inflate(
        value.slice(COMPRESSED_STRING_MAGIC.length) as unknown as pako.Data,
        { to: 'string' }
      )
    }

    try {
      return value ? (JSON.parse(value) as T) : null
    } catch (e) {
      logger.warning('Failed to parse stored value', {
        error: e,
        key,
        value,
      })
      return null
    }
  }

  static getWithDefaultValue<T>(key: string, defaultValue: T): T {
    let value = this.get<T>(key)
    if (value == null) {
      this.set<T>(key, defaultValue)
      value = defaultValue
    }
    return value
  }

  static remove(key: string): boolean {
    localStorage.removeItem(key)
    return true
  }

  static setIfEmpty<T>(key: string, defaultValue: T = {} as T): void {
    if (this.get<T>(key) == null) {
      this.set<T>(key, defaultValue)
    }
  }

  static setFromUrlParam<T = string>(
    param: string,
    key: string,
    getValueFunction?: (paramValue: string | null) => T
  ): void {
    if (!isClient()) return

    const paramValue = getUrlParam(param)
    if (paramValue !== undefined) {
      StorageManager.set<T>(
        key,
        getValueFunction
          ? getValueFunction(paramValue)
          : (paramValue as unknown as T)
      )
    }
  }

  static getKeysByRegex(regex: RegExp): string[] {
    if (!isClient()) return []
    return Object.keys(localStorage).filter((key) => regex.test(key))
  }
}

export default StorageManager
