export const ALL_UTM_PARAMS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'utm_campaign_id',
  'utm_adset_id',
  'utm_ad_id',
  'initial_user_id',
  'utm_home_exp',
  'utm_cv_exp',
  'fbclid',
]
