import i18n from 'i18next'
import { LANGUAGES, SUPPORTED_LANGUAGES } from '../locale/Translation.consts'
import { klaviyo } from './Analytics/platforms/klaviyo'

const FALLBACK_LANGUAGE = LANGUAGES.ENGLISH

export class TranslateManager {
  didInit = false

  language: string | null = null

  init(language: string) {
    if (this.didInit) {
      throw new Error('TranslateManager already initialized')
    }
    this.didInit = true

    this.language = language
    if (!SUPPORTED_LANGUAGES.includes(language as never)) {
      this.language = FALLBACK_LANGUAGE
    }
    klaviyo.setLanguage(this.language)
  }

  get(key: string, values?: any): string {
    const res = i18n.t(key, values)
    return Array.isArray(res) ? res.join('\n') : res.toString()
  }

  setLanguage(lang: string) {
    i18n.changeLanguage(lang)
    const url = new URL(window.location.href)
    url.searchParams.set('language', lang)
    window.location.href = url.href
  }

  getLanguage() {
    if (!this.language) {
      throw new Error(
        'Attempted to access language before TranslateManager init'
      )
    }

    return this.language
  }

  getDateFormat(): string | undefined {
    const dateFormats = {
      en: 'dddd, mmmm dS',
      es: "dddd, d 'de' mmmm",
      de: "dddd, d'.' mmmm",
      fr: 'dddd, d mmmm',
    }
    return dateFormats[this.getLanguage() as never]
  }

  localize(obj: any) {
    return obj[this.getLanguage()] || obj[FALLBACK_LANGUAGE]
  }
}

export const translateManager = new TranslateManager()
